import './App.css';
import Komponen from './komponen/Header';
import Modila from './komponen/Mod';

function App() {
  return (
    <div className="App">
      <Modila/>
    </div>
  );
}

export default App;
