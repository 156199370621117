import React from 'react';
import {ArrowRight} from 'react-bootstrap-icons';

function Mod() {
// 	const nama = "Modila"
// 	console.log(nama)
  return (
    
          <div>
          <div className="wrapper-gambar">
          <img className="backdrop gambar"/>
          </div>
                <h2 className="text-center" style={{color:"#ffffff" , paddingTop:"20px" , paddingBottom:"30px"}}>Modila Akbari</h2>





    <div className="container">

    <div className="col-xs-12">

            <div className="text-center">

                <div style={{paddingBottom:"30px"}}>

                    <a href='https://www.instagram.com/akbarimodila/' target="_blank" className="btn btn-outline-light shake" style={{width: "80%", paddingTop:"10px", paddingBottom:"10px", fontWeight: "600"}}>Instagram</a>

                </div>

                <div style={{paddingBottom:"30px"}}>

                    <a href='https://twitter.com/aojak' target="_blank" className="btn btn-outline-light shake" style={{width: "80%", paddingTop:"10px", paddingBottom:"10px", fontWeight: "600"}}>Twitter</a>

                </div>

                <div style={{paddingBottom:"30px"}}>

                    <a href='https://www.facebook.com/akbarimodila' target="_button" className="btn btn-outline-light shake" style={{width: "80%", paddingTop:"10px", paddingBottom:"10px", fontWeight: "600"}}>Facebook</a>

                </div>

                <div style={{paddingBottom:"30px"}}>

                    <a href='mailto:me@modilaakbari.com' target="_blank" className="btn btn-outline-light shake"style={{width: "80%", paddingTop:"10px", paddingBottom:"10px", fontWeight: "600"}}>Email Me For Business</a>

                </div>

            </div>

    </div>

    </div>



        <div className="text-center">

            <p className="footer-block">Made with <span style={{color: '#ef4444'}}>❤</span> only for you!</p>



        </div>

        </div>
  );
}

export default Mod;